import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { Linking } from './components/Linking';

import BACKEND_URL from '../config';

import './css/buy.css'
import './css/spinner.css'

const AudioTubes = () => {
  const [AudioTubes, setAudioTubes] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/get_product`, {
        params: { option: 'AudioTubes' }
      });
      setAudioTubes(response.data.get_list);
      setLoading(false)
    } catch (error) {
      console.error('Error during request: ', error);
      alert('Error during request');
    }
  };

  return (
    <>
      <div>
        <title>Аудіотрубки</title>
        <div className="catalog-div">
          <Linking />
        </div>
        {loading ? <div>
          <div className="spinner"></div>
          <div className='text-snr'><p>ЗАВАНТАЖУЄМО КОНТЕНТ...</p></div>
        </div> :
          <div>
            <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                {AudioTubes.map((image) => (
                  <div className='div-for-catalog' key={image.id} style={{opacity: image.avaliable ? '1' : '0.8'}}>
                    <p style={{ position: 'absolute', marginTop: '10px', fontSize: '25px' }}>{image.product}</p>
                    <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '20px' }}>{image.price}грн</p>
                    <img
                      src={image.url}
                      alt={image.filename}
                      style={{ blockSize: '250px', marginTop: '100px' }}
                    />
                    <Link to={`/pre-order-page/AudioTubes/${image.id}`} className='catalog-link'
                      style={{ position: 'absolute', marginTop: '370px', width: '270px', transform: 'scale(0.8)' }}>
                      {image.avaliable ? 'Замовити' : 'Немає у наявності'}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>}
      </div>
    </>
  );
};

export { AudioTubes };
