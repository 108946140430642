import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/buy.css';

import BACKEND_URL from '../../config';

const Buy = (props) => {
    const navigate = useNavigate();

    const [Name, setName] = useState('');
    const [Surname, setSurname] = useState('');
    const [Middlename, setMiddlename] = useState('');
    const [EmailPhone, setEmailPhone] = useState('');

    const [disableButton, setDisableButton] = useState(false)

    const DoNext = async (e) => {
        e.preventDefault();

        setDisableButton(true)

        navigate('/success');

        const formData = {
            Name: Name,
            Surname: Surname,
            Middlename: Middlename,
            EmailPhone: EmailPhone,
            Product: props.productNameList,
            Price: props.productPrice
        };

        const response = await fetch(`${BACKEND_URL}/get_order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        const responseData = await response.json();
        console.log(responseData);

        localStorage.removeItem("CartProducts")
        props.setProductNumber(0)

        if (props.setOpenCart) { props.setOpenCart(false) }
    }

    return (
        <form onSubmit={DoNext}>
            <div className="modal">
                <div className="modal-content" style={{ overflow: 'hidden', height: 'auto' }}>
                    <h2 style={{ textAlign: 'center' }}>Замовлення на суму</h2>
                    <h3 style={{ textAlign: 'center', textDecoration: 'underline', marginTop: '-20px' }}>{props.productPrice}грн</h3>
                    <p style={{ marginTop: '50px' }}>*Будь ласка, заповніть форму нижче:</p>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <div style={{width: '100%', marginRight: '20px', marginLeft: '0px'}}>
                            <div className="group" style={{ marginTop: '30px' }}>
                                <input
                                    className='inputWidth'
                                    type="text"
                                    required
                                    style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white', width: '100%'}}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <span className="bar"></span>
                                <label className='labelFont'>Ім'я</label>
                            </div>
                            <div className="group" style={{ marginTop: '30px' }}>
                                <input
                                    className='inputWidth'
                                    type="text"
                                    required
                                    style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white', width: '100%' }}
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                                <span className="bar"></span>
                                <label className='labelFont'>Прізвище</label>
                            </div>
                            <div className="group" style={{ marginTop: '30px' }}>
                                <input
                                    className='inputWidth'
                                    type="text"
                                    required
                                    style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white', width: '100%' }}
                                    onChange={(e) => setMiddlename(e.target.value)}
                                />
                                <span className="bar"></span>
                                <label className='labelFont'>Адреса</label>
                            </div>
                            <div className="group" style={{ marginTop: '30px', marginBottom: '50px'}}>
                                <input
                                    className='inputWidth'
                                    type="text"
                                    required
                                    style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white', width: '100%' }}
                                    onChange={(e) => setEmailPhone(e.target.value)}
                                />
                                <span className="bar"></span>
                                <label className='labelFont'>Номер телефону</label>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className='floating-button' style={{ marginLeft: '10px' }} onClick={props.closeWindow}>Назад</button>
                        <button
                            type='submit'
                            className='floating-button'
                            style={{ marginRight: '10px' }}
                            disabled={disableButton}
                        >
                            Далі
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export { Buy };
