import { useState } from "react";
import axios from "axios";

import BACKEND_URL from "../../config";

const AddImages = (props) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    const toAdd = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append('file', file);
        });
        formData.append('idClass', props.productID);
        formData.append('dbClass', props.productDBclass);

        try {
            await axios.post(`${BACKEND_URL}/add_images`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            props.closeWindow()

        } catch (error) {
            console.error('Error uploading files: ', error);
            alert('Error uploading files');
        }
    }


    return (
        <div>
            <div className="modal">
                <div className="modal-content" style={{ overflow: 'hidden', height: 'auto' }}>
                    <h2 style={{ textAlign: 'center' }}>Виберіть зображення:</h2>
                    <input type="file" onChange={handleFileChange} required style={{ marginTop: '20px', fontFamily: '"Montserrat", sans-serif', width: 'calc(100% - 20px)' }} />
                    {selectedFiles.map((file, index) => (
                        <img
                            key={index}
                            src={URL.createObjectURL(file)}
                            alt="Помилка відображення фото"
                            style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }}
                        />
                    ))}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <button className='floating-button' onClick={props.closeWindow}>Ні</button>
                        <button type='submit' className='floating-button' onClick={toAdd}>Додати</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AddImages }