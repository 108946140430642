import React from 'react';
import { Link } from 'react-router-dom';

import { IoCartOutline } from "react-icons/io5";

const CartIcon = (props) => {
    return (
        <div className='app-cart-div' style={{position: 'static'}} onClick={() => props.setOpenCart(true)}>
            <div style={{ position: 'relative'}}>
                <p className='app-cart-point'>●</p>
                <p className='app-cart-number' style={props.productNumber <= 9 ? { right: '-1px' } : { right: '-4px' }}>
                    {props.productNumber <= 9 ? props.productNumber : '9+'}
                </p>
                <IoCartOutline style={{ height: '25px', width: '25px' }} />
            </div>
        </div>
    );
}

export default CartIcon;
