import '../css/buy.css';
import { useState } from 'react';

import BACKEND_URL from '../../config';

const ChangePrice = (props) => {

    const [changingPrice, setChangingPrice] = useState()

    const pushPrice = async (e) => {
        e.preventDefault()

        const formData = {
            'id': props.productID,
            'dbClass': props.productDBclass,
            'price': changingPrice
        }

        try {
            await fetch(`${BACKEND_URL}/change_price`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            props.closeWindow();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }

    }

    return (
        <div style={{overflow: 'hidden'}}>
            <div className="modal">
                <div className="modal-content" style={{overflow: 'hidden', height: 'auto'}}>
                    <h2 style={{ textAlign: 'center' }}>Поточна ціна: {props.productPrice}$</h2>
                    <form>
                        <div className="group" style={{ marginTop: '30px' }}>
                            <input
                                type="text"
                                required
                                style={{ background: 'none', color: 'white', maxWidth: '100%', width: 'calc(100% - 20px)' }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '' || (!isNaN(value) && !value.includes('.'))) {
                                        setChangingPrice(value);
                                    }
                                }
                                }
                                value={changingPrice}
                            />
                            <span className="bar"></span>
                            <label>*Нова ціна товару в доларах</label>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <button className='floating-button' onClick={props.closeWindow}>Закрити</button>
                        <button type='submit' className='floating-button' onClick={pushPrice}>Змінити</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export { ChangePrice };
