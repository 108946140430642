import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BACKEND_URL from '../../config';

const DoYouWantToChangeAvaliableStatus = (props) => {
    const [avaliableStatus, setAvaliableStatus] = useState()

    useEffect(() => {
        axios
            .get(`${BACKEND_URL}/avaliable-status/${props.productDBclass}/${props.productID}`)
            .then((res) => {
                setAvaliableStatus(res.data)
            })
    }, [])

    function ChangeAvaliableStatus() {
        axios.post(`${BACKEND_URL}/avaliable-status/${props.productDBclass}/${props.productID}`)
        props.closeWindow()
    }

    return (
        <div>
            {avaliableStatus &&
                <div className="modal">
                    <div className="modal-content" style={{ overflow: 'hidden', height: 'auto' }}>
                        <h2 style={{ textAlign: 'center' }}>Ви дійсно хочете позначити цей товар як {avaliableStatus.status ? '"Немає у наявності"' : '"У наявності"'}?</h2>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button className='floating-button' onClick={props.closeWindow}>Ні</button>
                            <button type='submit' className='floating-button' onClick={ChangeAvaliableStatus}>Так</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default DoYouWantToChangeAvaliableStatus;
