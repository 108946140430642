import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom'

import { CurrentIDProvider } from './CurrentIDContext';

import './css/manage-about-us.css'
import './css/pre-order-page.css'
import './css/app.css'
import './css/cart.css'
import './css/cart-icon.css'
import './css/main-modal.css'
import './css/catalog.css'
import './css/view-images-for-admin.css'
import './css/linking.css'

import { HomePage } from './pages/HomePage';
import { ErrorPage } from './pages/ErrorPage'
import { VideoIntercom } from './pages/VideoIntercom';
import { IpIntercom } from './pages/IpIntercom';
import { AboutUs } from './pages/AboutUs';
import { Support } from './pages/Support';
import { Catalog } from './pages/Catalog';
import { Success } from './pages/Success';
import { AudioTubes } from './pages/AudioTubes';
import { CallPanels } from './pages/CallPanels';
import { Dahua } from './pages/Dahua';
import { Accessories } from './pages/Accessories';
import { CheckOrders } from './pages/CheckOrders';
import { HistoryOrders } from './pages/HistoryOrders';
import { TestWatchImg } from './pages/TestWatchImg';
import { Upload } from './pages/Upload';
import { UploadMore } from './pages/UploadMore';
import { SuccessSupport } from './pages/SuccessSupport';
import { CheckQuestions } from './pages/CkeckQuestions';
import { CheckAll } from './pages/CheckAll'
import { HistoryQuestions } from './pages/HistoryQuestions';
import { AddTelegramID } from './pages/AddTelegramID';
import ManageAboutUs from './pages/ManageAboutUs';
import PreOrderPage from './pages/PreOrderPage';
import Cart from './pages/components/Cart';
import EquipmentKit from './pages/EquipmentKit';

import { IoCartOutline } from "react-icons/io5";

import CartIcon from './pages/components/CartIcon';

import phone from './images/phone.png'
import threelines from './images/threelines.png'


function App() {
  const [openCart, setOpenCart] = useState(false)
  const [productNumber, setProductNumber] = useState(0)

  useEffect(() => {
    const data = localStorage.getItem("CartProducts");
    if (data) {
      const parsedData = JSON.parse(data);
      setProductNumber(parsedData.length)
    }
  }, []);

  return (
    <>
      <div>
        <header className='static-block'>
          <div style={{ backgroundColor: 'rgb(10, 10, 10)', height: '75px' }}>
            <div style={{ marginTop: '75px' }}>
              <div style={{ marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                <div className='display-div'>
                  {productNumber > 0 && window.innerWidth >= 850 &&
                    <div className='app-cart-div' onClick={() => setOpenCart(true)}>
                      <div style={{ position: 'relative' }}>
                        <p className='app-cart-point'>●</p>
                        <p className='app-cart-number' style={productNumber <= 9 ? { right: '-1px' } : { right: '-4px' }}>
                          {productNumber <= 9 ? productNumber : '9+'}
                        </p>
                        <IoCartOutline style={{ height: '25px', width: '25px' }} />
                      </div>
                    </div>
                  }
                  <Link className="butt" style={{ marginLeft: productNumber > 0 && window.innerWidth <= 850 ? '0px' : '-40px' }} to="/">Домосервіс</Link>
                  <Link className="butt" to="/about-us">Про Нас</Link>
                  <Link className="butt" to="/support">Тех. Підтримка</Link>
                  {productNumber > 0 && window.innerWidth <= 850 ?
                    <CartIcon productNumber={productNumber} setOpenCart={setOpenCart} />
                    :
                    <div className='header-phone' style={{ height: '100px', marginLeft: '-25px' }}>
                      <img src={phone} alt='phone' style={{ width: '20px', height: '20px', position: 'absolute', marginTop: '10px', marginLeft: '-50px' }} />
                      <div style={{ width: '200px', marginTop: '-15px' }}>
                        <p className="home">098-244-44-34<br></br><span className="home" style={{ fontSize: '16.3px', top: '-50px' }}>032-244-44-34</span></p>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className='onOff'>
                <div className='div-phone'>
                  <Link className="butt2" style={{ marginRight: '50px' }} to="/">Домосервіс</Link>
                  {productNumber > 0 && window.innerWidth <= 850 ?
                      <CartIcon productNumber={productNumber} setOpenCart={setOpenCart} />
                    :
                    <div className='header-phone' style={{ height: '100px', float: 'right' }}>
                      <img src={phone} alt='phone' style={{ width: '20px', height: '20px', position: 'absolute', marginTop: '10px', marginLeft: '-25px' }} />
                      <div style={{ width: '120px', marginTop: '-15px' }}>
                        <p className="home">098-244-44-34<br></br><span className="home" style={{ fontSize: '16.3px', top: '-50px' }}>032-244-44-34</span></p>
                      </div>
                    </div>
                  }
                </div>
                <div className="menuposs3" style={productNumber > 0 && window.innerWidth <= 510 ? {marginTop: '-40px'} : {}}>
                  <ul className="menu">
                    <li style={{ width: '20px' }}>
                      <img src={threelines} alt='threelines' style={{ height: '20px', width: '20px' }} className="listtop" />
                      <ul className="list">
                        <li><Link to="/about-us">Про Нас</Link></li>
                        <li><Link to="/support">Тех. Підтримка</Link></li>
                        <li className='onOffPhoneMenu'>
                          <Link className="home">098-244-44-34</Link>
                        </li>
                        <li className='onOffPhoneMenu'>
                          <Link className="home">032-244-44-34</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        {openCart && <Cart setOpenCart={setOpenCart} setProductNumber={setProductNumber} productNumber={productNumber}/>}
      </div>
      <CurrentIDProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/support" element={<Support />} />
          <Route path="/video-intercom" element={<VideoIntercom />} />
          <Route path="/ip-intercom" element={<IpIntercom />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/success-support" element={<SuccessSupport />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/audiotubes" element={<AudioTubes />} />
          <Route path="/call-panels" element={<CallPanels />} />
          <Route path="/dahua" element={<Dahua />} />
          <Route path="/accessories" element={<Accessories />} />
          <Route path="/check-orders" element={<CheckOrders />} />
          <Route path="/history-orders" element={<HistoryOrders />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/test-watch-img" element={<TestWatchImg />} />
          <Route path="/upload-more" element={<UploadMore />} />
          <Route path="/check-questions" element={<CheckQuestions />} />
          <Route path="/history-questions" element={<HistoryQuestions />} />
          <Route path="/check-questions/check-all" element={<CheckAll />} />
          <Route path="/history-questions/check-all" element={<CheckAll />} />
          <Route path="/add-telegram-id" element={<AddTelegramID />} />
          <Route path="/manage-about-us" element={<ManageAboutUs />} />
          <Route path="/equipment-kits" element={<EquipmentKit />} />
          <Route path="/pre-order-page/:option/:id" element={<PreOrderPage setProductNumber={setProductNumber} productNumber={productNumber} />} />
        </Routes>
      </CurrentIDProvider>
      <footer style={{ textAlign: 'center', opacity: '0.5' }}>
        <p style={{ marginTop: '100px', fontSize: '20px' }}>Тов Домосервіс</p>
        <p>Адреса:</p>
        <p style={{ marginTop: '-15px' }}>м. Львів</p>
        <p style={{ marginTop: '-15px' }}>вул. Зелена 115А</p>
        <p style={{ marginTop: '-10px', marginBottom: '100px' }}>© 2008-2025</p>
      </footer>
    </>
  );
}

export default App;