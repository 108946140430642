import React, { useState, useEffect } from 'react';
import axios from 'axios';

import BACKEND_URL from '../config';

import { Link } from 'react-router-dom';
import { Delete } from './components/Delete';
import { AddImages } from './components/AddImages';
import { ViewImagesForAdmin } from './components/ViewImagesForAdmin';
import { ViewDescription } from './components/ViewDescription';
import { MainModal } from './components/MainModal';
import { ChangeName } from './components/ChangeName';
import { ChangePrice } from './components/ChangePrice';
import { ChangeDesc } from './components/ChangeDesc';
import { ChangeImage } from './components/ChangeImage';
import { CheckPsw } from './components/CheckPsw';
import './css/buy.css'
import './css/spinner.css'

const UploadMore = () => {
  const [VideoIntercom, setVideoIntercom] = useState([]);
  const [IpIntercom, setIpIntercom] = useState([]);
  const [CallPanels, setCallPanels] = useState([]);
  const [AudioTubes, setAudioTubes] = useState([]);
  const [EquipmentKits, setEquipmentKits] = useState([])
  const [Dahua, setDahua] = useState([]);
  const [Accessories, setAccessories] = useState([]);
  const [ListImages, setListImages] = useState([]);
  const [checkOrder, setCheckOrder] = useState(false);
  const [checkImages, setCheckImages] = useState(false);
  const [checkViewImages, setCheckViewImages] = useState(false);
  const [productID, setProductID] = useState("Default!");
  const [productName, setProductName] = useState("Default!");
  const [productDBclass, setProductDBclass] = useState("Default!");
  const [productPrice, setProductPrice] = useState("Default!");
  const [productDesc, setProductDesc] = useState("Default!");
  const [currentImages, setCurrentImages] = useState([]);
  const [checkDesc, setCheckDesc] = useState(false)
  const [desc, setDesc] = useState()
  const [loading, setLoading] = useState(true)
  const [mainModal, setMainModal] = useState(false)
  const [changeName, setChangeName] = useState(false)
  const [changePrice, setChangePrice] = useState(false)
  const [changeDesc, setChangeDesc] = useState(false)
  const [changeImg, setChangeImg] = useState(false)

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/upload_more`);
      setVideoIntercom(response.data.video_intercom);
      setIpIntercom(response.data.ip_intercom);
      setAudioTubes(response.data.audiotubes);
      setCallPanels(response.data.call_panels);
      setDahua(response.data.dahua);
      setAccessories(response.data.accessories);
      setEquipmentKits(response.data.equipment_kit);
      setListImages(response.data.list_images);
      setLoading(false)
    } catch (error) {
      console.error('Error during request: ', error);
      alert('Error during request');
    }
  };

  function gettingEl(e) {
    setProductID(e.target.id);
    setProductDBclass(e.target.getAttribute('dbClass'))
    setCheckOrder(true);
  }

  function openWindow(e) {
    setProductID(e.target.id);
    setProductName(e.target.name);
    setProductDBclass(e.target.getAttribute('dbClass'))
    setProductPrice(e.target.getAttribute('price'))
    setProductDesc(e.target.getAttribute('desc'))
    setMainModal(true);
  }

  function viewImages(e, images) {
    setCurrentImages(images);
    setCheckViewImages(true);
  }

  function checkImg() {
    setMainModal(false)
    setCheckImages(true)
  }

  function openWinDesc(e) {
    setDesc(e.target.id)
    setCheckDesc(true)
  }

  function openChangeName() {
    setMainModal(false)
    setChangeName(true)
  }

  function openChangePrice() {
    setMainModal(false)
    setChangePrice(true)
  }

  function openChangeDesc() {
    setMainModal(false)
    setChangeDesc(true)
  }
  function openChangeImg() {
    setMainModal(false)
    setChangeImg(true)
  }

  function closeWindow() {
    setCheckOrder(false);
    setCheckImages(false);
    setCheckViewImages(false);
    setCheckDesc(false)
    setMainModal(false)
    setChangeName(false)
    setChangePrice(false)
    setChangeDesc(false)
    setChangeImg(false)
    fetchImages();
  };

  const [isCorrect, setIsCorrect] = useState(false)
  const [msg, setMsg] = useState('')

  useEffect(() => {
    const storedIsCorrect = localStorage.getItem('isCorrect');
    if (storedIsCorrect === 'true') {
      setIsCorrect(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('isCorrect', isCorrect.toString());
  }, [isCorrect]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', fontFamily: '"Montserrat", sans-serif', marginTop: '200px' }}>
      <title>Панель керування</title>
      {isCorrect ?
        <div>
          {loading ? <div>
            <div className="spinner"></div>
            <div className='text-snr'><p>ЗАВАНТАЖУЄМО КОНТЕНТ...</p></div>
          </div> :
            <div>
              <div>
                <div>
                  <h1 style={{ textAlign: 'center', marginTop: '0px' }}>АУДІОТРУБКИ</h1>
                  <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                      {AudioTubes.map((image) => (
                        <div className='div-for-catalog' key={image.id}>
                          <p style={{ position: 'absolute', marginTop: '10px', fontSize: '25px' }}>{image.product}</p>
                          <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '20px' }}>{image.price}$</p>
                          <img
                            src={image.url}
                            alt={image.filename}
                            style={{ blockSize: '180px', marginTop: '80px' }}
                            onClick={(e) => viewImages(e, [image])}
                          />
                          <div style={{ position: 'absolute', display: 'flex', marginTop: '270px' }}>
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 2).map((el) => (
                              <div style={{ margin: '5px' }} key={el.id}>
                                <img
                                  src={el.url}
                                  alt='none'
                                  style={{ blockSize: '60px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                                  onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                                />
                              </div>
                            ))}
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 3 && (
                              <div style={{ display: 'flex', marginTop: '30px' }}>
                                <p>+{ListImages.filter(el => image.id === el.idClass).length - 2}</p>
                              </div>
                            )}
                          </div>
                          <Link id={image.id} name={image.product} dbClass={image.dbClass} price={image.price} desc={image.description} onClick={openWindow} className='catalog-link'
                            style={{ position: 'absolute', marginTop: '370px', width: '270px', transform: 'scale(0.8)' }}>
                            Редагувати
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style={{ textAlign: 'center', marginTop: '50px' }}>ВІДЕОДОМОФОНИ</h1>
                  <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                      {VideoIntercom.map((image) => (
                        <div className='div-for-catalog-two' key={image.id}>
                          <p style={{ position: 'absolute', marginTop: '10px', fontSize: '30px' }}>{image.product}</p>
                          <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '25px' }}>{image.price}$</p>
                          <img
                            src={image.url}
                            alt={image.filename}
                            style={{ blockSize: '250px', marginTop: '90px', position: 'absolute' }}
                          />
                          <div style={{ position: 'absolute', display: 'flex', marginTop: '350px' }}>
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 3).map((el) => (
                              <div style={{ margin: '5px' }} key={el.id}>
                                <img
                                  src={el.url}
                                  alt='none'
                                  style={{ blockSize: '80px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                                  onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                                />
                              </div>
                            ))}
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 4 && (
                              <div style={{ display: 'flex', marginTop: '50px' }}>
                                <p>+{ListImages.filter(el => image.id === el.idClass).length - 3}</p>
                              </div>
                            )}
                          </div>
                          <div style={{ position: 'absolute', marginTop: '440px' }}>
                            {image.description.length > 250 ?
                              <div>
                                <div className='description'>
                                  <p>{image.description.slice(0, 247) + '...'}</p>
                                </div>
                                <div style={{ marginLeft: '100px', width: '300px' }}>
                                  <Link className='link-for-text' id={image.description} onClick={openWinDesc}>Переглянути увесь опис товару↴</Link>
                                </div>
                              </div>
                              :
                              <div className='description'>
                                <p>{image.description}</p>
                              </div>}
                          </div>
                          <Link id={image.id} name={image.product} dbClass={image.dbClass} price={image.price} desc={image.description} onClick={openWindow} className='catalog-link'
                            style={{ position: 'absolute', marginTop: '640px', width: '270px' }}>
                            Редагувати
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style={{ textAlign: 'center', marginTop: '50px' }}>ВИКЛИЧНІ ПАНЕЛІ</h1>
                  <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                      {CallPanels.map((image) => (
                        <div className='div-for-catalog-two' key={image.id}>
                          <p style={{ position: 'absolute', marginTop: '10px', fontSize: '30px' }}>{image.product}</p>
                          <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '25px' }}>{image.price}$</p>
                          <img
                            src={image.url}
                            alt={image.filename}
                            style={{ blockSize: '250px', marginTop: '90px', position: 'absolute' }}
                          />
                          <div style={{ position: 'absolute', display: 'flex', marginTop: '350px' }}>
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 3).map((el) => (
                              <div style={{ margin: '5px' }} key={el.id}>
                                <img
                                  src={el.url}
                                  alt='none'
                                  style={{ blockSize: '80px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                                  onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                                />
                              </div>
                            ))}
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 4 && (
                              <div style={{ display: 'flex', marginTop: '50px' }}>
                                <p>+{ListImages.filter(el => image.id === el.idClass).length - 3}</p>
                              </div>
                            )}
                          </div>
                          <div style={{ position: 'absolute', marginTop: '440px' }}>
                            {image.description.length > 250 ?
                              <div>
                                <div className='description'>
                                  <p>{image.description.slice(0, 247) + '...'}</p>
                                </div>
                                <div style={{ marginLeft: '100px', width: '300px' }}>
                                  <Link className='link-for-text' id={image.description} onClick={openWinDesc}>Переглянути увесь опис товару↴</Link>
                                </div>
                              </div>
                              :
                              <div className='description'>
                                <p>{image.description}</p>
                              </div>}
                          </div>
                          <Link id={image.id} name={image.product} dbClass={image.dbClass} price={image.price} desc={image.description} onClick={openWindow} className='catalog-link'
                            style={{ position: 'absolute', marginTop: '640px', width: '270px' }}>
                            Редагувати
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style={{ textAlign: 'center', marginTop: '50px' }}>IP-ДОМОФОНИ</h1>
                  <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                      {IpIntercom.map((image) => (
                        <div className='div-for-catalog-three' key={image.id} style={{ marginLeft: '10px', marginRight: '10px' }}>
                          <p style={{ position: 'absolute', marginTop: '10px', fontSize: '25px' }}>{image.product}</p>
                          <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '20px' }}>{image.price}$</p>
                          <img
                            src={image.url}
                            alt={image.filename}
                            style={{ blockSize: '180px', marginTop: '80px' }}
                            onClick={(e) => viewImages(e, [image])}
                          />
                          <div style={{ position: 'absolute', display: 'flex', marginTop: '270px' }}>
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 3).map((el) => (
                              <div style={{ margin: '5px' }} key={el.id}>
                                <img
                                  src={el.url}
                                  alt='none'
                                  style={{ blockSize: '80px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                                  onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                                />
                              </div>
                            ))}
                            {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 4 && (
                              <div style={{ display: 'flex', marginTop: '50px' }}>
                                <p>+{ListImages.filter(el => image.id === el.idClass).length - 3}</p>
                              </div>
                            )}
                          </div>
                          <div style={{ position: 'absolute', marginTop: '350px', transform: 'scale(0.8)' }}>
                            {image.description.length > 60 ?
                              <div>
                                <div className='description'>
                                  <p>{image.description.slice(0, 57) + '...'}</p>
                                </div>
                                <div style={{ marginLeft: '100px', width: '300px' }}>
                                  <Link className='link-for-text' id={image.description} onClick={openWinDesc}>Переглянути увесь опис товару↴</Link>
                                </div>
                              </div>
                              :
                              <div className='description'>
                                <p>{image.description}</p>
                              </div>}
                          </div>
                          <Link id={image.id} name={image.product} dbClass={image.dbClass} price={image.price} desc={image.description} onClick={openWindow} className='catalog-link'
                            style={{ position: 'absolute', marginTop: '440px', width: '270px', transform: 'scale(0.8)' }}>
                            Редагувати
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h1 style={{ textAlign: 'center', marginTop: '50px' }}>DAHUA</h1>
                <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                    {Dahua.map((image) => (
                      <div className='div-for-catalog-two' key={image.id}>
                        <p style={{ position: 'absolute', marginTop: '10px', fontSize: '30px' }}>{image.product}</p>
                        <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '25px' }}>{image.price}$</p>
                        <img
                          src={image.url}
                          alt={image.filename}
                          style={{ blockSize: '250px', marginTop: '90px', position: 'absolute' }}
                        />
                        <div style={{ position: 'absolute', display: 'flex', marginTop: '350px' }}>
                          {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 3).map((el) => (
                            <div style={{ margin: '5px' }} key={el.id}>
                              <img
                                src={el.url}
                                alt='none'
                                style={{ blockSize: '80px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                                onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                              />
                            </div>
                          ))}
                          {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 4 && (
                            <div style={{ display: 'flex', marginTop: '50px' }}>
                              <p>+{ListImages.filter(el => image.id === el.idClass).length - 3}</p>
                            </div>
                          )}
                        </div>
                        <div style={{ position: 'absolute', marginTop: '440px' }}>
                          {image.description.length > 250 ?
                            <div>
                              <div className='description'>
                                <p>{image.description.slice(0, 247) + '...'}</p>
                              </div>
                              <div style={{ marginLeft: '100px', width: '300px' }}>
                                <Link className='link-for-text' id={image.description} onClick={openWinDesc}>Переглянути увесь опис товару↴</Link>
                              </div>
                            </div>
                            :
                            <div className='description'>
                              <p>{image.description}</p>
                            </div>}
                        </div>
                        <Link id={image.id} name={image.product} dbClass={image.dbClass} price={image.price} desc={image.description} onClick={openWindow} className='catalog-link'
                          style={{ position: 'absolute', marginTop: '640px', width: '270px' }}>
                          Редагувати
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h1 style={{ textAlign: 'center', marginTop: '50px', wordBreak: 'break-all', marginLeft: '10px', marginRight: '10px' }}>ДОДАТКОВІ АКСЕСУАРИ</h1>
                <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                    {Accessories.map((image) => (
                      <div className='div-for-catalog-two' key={image.id}>
                        <p style={{ position: 'absolute', marginTop: '10px', fontSize: '30px' }}>{image.product}</p>
                        <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '25px' }}>{image.price}$</p>
                        <img
                          src={image.url}
                          alt={image.filename}
                          style={{ blockSize: '250px', marginTop: '90px', position: 'absolute' }}
                        />
                        <div style={{ position: 'absolute', display: 'flex', marginTop: '350px' }}>
                          {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 3).map((el) => (
                            <div style={{ margin: '5px' }} key={el.id}>
                              <img
                                src={el.url}
                                alt='none'
                                style={{ blockSize: '80px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                                onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                              />
                            </div>
                          ))}
                          {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 4 && (
                            <div style={{ display: 'flex', marginTop: '50px' }}>
                              <p>+{ListImages.filter(el => image.id === el.idClass).length - 3}</p>
                            </div>
                          )}
                        </div>
                        <div style={{ position: 'absolute', marginTop: '440px' }}>
                          {image.description.length > 250 ?
                            <div>
                              <div className='description'>
                                <p>{image.description.slice(0, 247) + '...'}</p>
                              </div>
                              <div style={{ marginLeft: '100px', width: '300px' }}>
                                <Link className='link-for-text' id={image.description} onClick={openWinDesc}>Переглянути увесь опис товару↴</Link>
                              </div>
                            </div>
                            :
                            <div className='description'>
                              <p>{image.description}</p>
                            </div>}
                        </div>
                        <Link id={image.id} name={image.product} dbClass={image.dbClass} price={image.price} desc={image.description} onClick={openWindow} className='catalog-link'
                          style={{ position: 'absolute', marginTop: '640px', width: '270px' }}>
                          Редагувати
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h1 style={{ textAlign: 'center', marginTop: '50px', wordBreak: 'break-all', marginLeft: '10px', marginRight: '10px' }}>КОМПЛЕКТИ ОБЛАДНАННЯ</h1>
                <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                    {EquipmentKits.map((image) => (
                      <div className='div-for-catalog-two' key={image.id}>
                        <p style={{ position: 'absolute', marginTop: '10px', fontSize: '30px' }}>{image.product}</p>
                        <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '25px' }}>{image.price}$</p>
                        <img
                          src={image.url}
                          alt={image.filename}
                          style={{ blockSize: '250px', marginTop: '90px', position: 'absolute' }}
                        />
                        <div style={{ position: 'absolute', display: 'flex', marginTop: '350px' }}>
                          {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 3).map((el) => (
                            <div style={{ margin: '5px' }} key={el.id}>
                              <img
                                src={el.url}
                                alt='none'
                                style={{ blockSize: '80px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                                onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                              />
                            </div>
                          ))}
                          {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 4 && (
                            <div style={{ display: 'flex', marginTop: '50px' }}>
                              <p>+{ListImages.filter(el => image.id === el.idClass).length - 3}</p>
                            </div>
                          )}
                        </div>
                        <div style={{ position: 'absolute', marginTop: '440px' }}>
                          {image.description.length > 250 ?
                            <div>
                              <div className='description'>
                                <p>{image.description.slice(0, 247) + '...'}</p>
                              </div>
                              <div style={{ marginLeft: '100px', width: '300px' }}>
                                <Link className='link-for-text' id={image.description} onClick={openWinDesc}>Переглянути увесь опис товару↴</Link>
                              </div>
                            </div>
                            :
                            <div className='description'>
                              <p>{image.description}</p>
                            </div>}
                        </div>
                        <Link id={image.id} name={image.product} dbClass={image.dbClass} price={image.price} desc={image.description} onClick={openWindow} className='catalog-link'
                          style={{ position: 'absolute', marginTop: '640px', width: '270px' }}>
                          Редагувати
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }

          {checkImages &&
            <AddImages productDBclass={productDBclass} productID={productID} closeWindow={closeWindow} />
          }
          {checkViewImages &&
            <ViewImagesForAdmin images={currentImages} closeWindow={closeWindow} />
          }
          {checkDesc &&
            <ViewDescription desc={desc} closeWindow={closeWindow} />
          }
          {mainModal &&
            <MainModal setCheckOrder={setCheckOrder} productID={productID} productName={productName} productDBclass={productDBclass} checkImg={checkImg} openChangeName={openChangeName}
              openChangePrice={openChangePrice} openChangeDesc={openChangeDesc} openChangeImg={openChangeImg} closeWindow={closeWindow} />
          }
          {checkOrder &&
            <Delete productDBclass={productDBclass} productID={productID} closeWindow={closeWindow} />
          }
          {changeName &&
            <ChangeName productID={productID} productName={productName} productDBclass={productDBclass} closeWindow={closeWindow} />
          }
          {changePrice &&
            <ChangePrice productID={productID} productPrice={productPrice} productDBclass={productDBclass} closeWindow={closeWindow} />
          }
          {changeDesc &&
            <ChangeDesc productID={productID} productDesc={productDesc} productDBclass={productDBclass} closeWindow={closeWindow} />
          }
          {changeImg &&
            <ChangeImage productID={productID} productDBclass={productDBclass} closeWindow={closeWindow} />
          }
        </div>
        :
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h3 style={{ position: 'absolute', top: '150px', color: 'red' }}>{msg}</h3>
          </div>
          <CheckPsw setIsCorrect={setIsCorrect} setMsg={setMsg} />
        </div>
      }
    </div>
  );
};

export { UploadMore };
