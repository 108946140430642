import '../css/buy.css';
import { Link } from 'react-router-dom';
import React, {useState} from 'react';

import DoYouWantToChangeAvaliableStatus from './DoYouWantToChangeAvaliableStatus';

const MainModal = (props) => {
    const [openAvaliableStatus, setOpenAvaliableStatus] = useState(false)

    return (
        <div>
            <div className="modal">
                <div className="modal-content" style={{ width: '500px', height: 'auto' }}>
                    <h2 style={{ textAlign: 'center' }}>Керування товаром</h2>
                    <h3 style={{ textAlign: 'center' }}>{props.productName}</h3>
                    <div>
                        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '0px' }}>
                            <div>
                                <div className='main-modal-link-button-div'>
                                    <Link className='main-modal-link-button' onClick={props.openChangeName}>Змінити назву</Link>
                                </div>
                                <div className='main-modal-link-button-div'>
                                    <Link className='main-modal-link-button' onClick={props.openChangePrice}>Змінити ціну</Link>
                                </div>
                                <div className='main-modal-link-button-div'>
                                    <Link className='main-modal-link-button' onClick={props.openChangeImg}>Змінити головне зображення</Link>
                                </div>
                                <div className='main-modal-link-button-div'>
                                    <Link className='main-modal-link-button' onClick={props.checkImg}>Додати додаткове зображення</Link>
                                </div>
                                <div className='main-modal-link-button-div'>
                                    <Link className='main-modal-link-button' onClick={props.openChangeDesc}>Змінити опис</Link>
                                </div>
                                <div className='main-modal-link-button-div'>
                                    <Link className='main-modal-link-button' onClick={() => setOpenAvaliableStatus(true)}>Не в наявності</Link>
                                </div>
                                <div className='main-modal-link-button-div'>
                                    <Link className='main-modal-link-button' style={{color: 'red'}} onClick={() => props.setCheckOrder(true)}>Видалити товар</Link>
                                </div>
                                <div className='main-modal-link-button-div' style={{marginBottom: '20px'}}>
                                    <Link className='main-modal-link-button' onClick={props.closeWindow}>Закрити</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openAvaliableStatus && <DoYouWantToChangeAvaliableStatus closeWindow={() => setOpenAvaliableStatus(false)}
                productID={props.productID} productDBclass={props.productDBclass}/>}
        </div>
    );
};

export { MainModal };
