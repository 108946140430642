import for_about_main from '../images/for_about.png'
import axios from 'axios'
import { useEffect, useState } from 'react'
import BACKEND_URL from '../config'

const AboutUs = () => {
    const [content, setContent] = useState("")
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        axios
            .post(`${BACKEND_URL}/get-current-article`, { id: 1 })
            .then((res) => {
                setContent(res.data.text)
                setLoader(true)
            })
    }, [])

    return (
        <div className='about-us-width'>
            <title>Про Нас</title>
            {!loader ?
                <div>
                    <div className="spinner"></div>
                    <div className='text-snr'><p>ЗАВАНТАЖУЄМО КОНТЕНТ...</p></div>
                </div>
                :
                <div>
                    <div style={{ display: 'flex', position: 'relative'}}>
                        <img src={for_about_main} style={{ blockSize: '50px' }} alt='about-img' />
                        <p style={{ color: 'rgb(252, 97, 22)', position: 'absolute', left: '147px', top: '24px', fontSize: '12px' }}>®</p>
                        <div style={{marginLeft: '0px', marginTop: '-18px'}}>
                            <h2 style={{ color: 'rgb(252, 97, 22)' }}>ДОМОФОН</h2>
                            <h2 style={{ color: 'rgb(252, 97, 22)', marginTop: '-28px' }}>СЕРВІС</h2>
                        </div>
                    </div>
                    <div
                        style={{ whiteSpace: 'pre-wrap', marginBottom: '100px' }}
                        dangerouslySetInnerHTML={{ __html: `<div class="custom-content">${content}</div>` }}
                    />
                </div>
            }
        </div>
    )
}

export { AboutUs }