import React, { useState, useEffect } from 'react';
import '../css/buy.css';

import BACKEND_URL from '../../config';

import { Buy } from './Buy';

const Cart = (props) => {
    const [productList, setProductList] = useState([]);
    const [productSum, setProductSum] = useState(0)

    const [openBuyWindow, setOpenBuyWindow] = useState(false)

    useEffect(() => {
        const data = localStorage.getItem("CartProducts");
        if (data) {
            const parsedData = JSON.parse(data);
            setProductList(parsedData);

            const sum = parsedData.reduce((acc, el) => acc + el.price, 0);
            setProductSum(sum);
        }
    }, []);

    function deleteProductFromCart(id, dbClass) {
        let items = JSON.parse(localStorage.getItem('CartProducts')) || [];
        items = items.filter(item => item.id !== id || item.dbClass !== dbClass);
        localStorage.setItem('CartProducts', JSON.stringify(items));

        setProductList((prev) => prev.filter((el) => el.id !== id || el.dbClass !== dbClass));
        setProductSum(items.reduce((acc, el) => acc + el.price, 0));
        props.setProductNumber(props.productNumber - 1)

        if (props.id) {
            if (props.id === id) {
                props.setInCart(false)
            }
        }
    }

    function getProductNameList() {
        const list = []
        productList.map((el) => {
            list.push(el.product)
        })

        return list
    }

    return (
        <>
            {!openBuyWindow ?
                <div className="modal">
                    <div className="modal-content" style={{ overflow: 'hidden', width: '100%', maxWidth: '500px', marginLeft: '15px', marginRight: '15px', display: 'flex', flexDirection: 'column', height: '80vh' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h2 style={{ marginTop: '0px' }}>Ваш кошик</h2>
                            <h2 style={{ marginTop: '0px' }}>Сума: <span style={{ textDecoration: 'underline' }}>{productSum}₴</span></h2>
                        </div>
                        <div style={{ flex: 1, overflowY: 'auto', paddingBottom: '10px' }}>
                            {productList.length > 0 ?
                                <div>
                                    {productList.map((el) => (
                                        <div className='cart-product-div' key={el.id} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                            <div style={{ margin: '5px', display: 'flex', alignItems: 'center', width: '100px', height: '100px', justifyContent: 'center' }}>
                                                <img src={el.url} alt={el.filename} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                            </div>
                                            <div>
                                                <h3 style={{ marginTop: '0px' }}>{el.product}</h3>
                                                <p style={{ marginTop: '-10px', marginBottom: '0px' }}>
                                                    Ціна: <span style={{ textDecoration: 'underline' }}>{el.price}грн</span>
                                                </p>
                                            </div>
                                            <p className='cart-delete-text' onClick={() => deleteProductFromCart(el.id, el.dbClass)}>Видалити</p>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}>
                                    <h2 style={{ opacity: '0.5' }}>Тут поки що пусто...</h2>
                                </div>
                            }
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderTop: '1px solid #ccc', marginBottom: '-20px', marginTop: '0px' }}>
                            <button className='floating-button' style={{ marginLeft: '10px', marginTop: '10px' }} onClick={() => props.setOpenCart(false)}>Назад</button>
                            <button
                                type='submit'
                                className='floating-button'
                                style={{ marginRight: '10px', marginTop: '10px', opacity: props.productNumber === 0 ? '0.5' : '1' }}
                                disabled={props.productNumber === 0 ? true : false}
                                onClick={() => setOpenBuyWindow(true)}
                            >
                                Далі
                            </button>
                        </div>
                    </div>
                </div>
                :
                <Buy setOpenCart={props.setOpenCart} productPrice={productSum} setProductNumber={props.setProductNumber} productNameList={getProductNameList()} closeWindow={() => setOpenBuyWindow(false)} />
            }
        </>
    );
};

export default Cart;
