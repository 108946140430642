import { Link } from "react-router-dom";

import audiotube from '../images/one.png';
import videointercom from '../images/two.png';
import equipmentkit from '../images/three.png'
import callpanel from '../images/9.png'

const Linking = (props) => {
  const currentPage = window.location.pathname

  return (
    <div className="catalog-div-main" style={{ width: '100%' }}>
      <div>
        <div className="catalog-div" style={{ width: '100%' }}>
          <Link
            className="catalogButt"
            to='/audiotubes'
            style={currentPage === '/audiotubes' ? { transform: 'scale(1.1)', color: 'rgb(223, 223, 0)' } : {}}
          >
            <img className="linking-image" src={audiotube} alt="Audiotube" />
            <p>Аудіо трубки</p>
          </Link>
          <Link
            className="catalogButt"
            to='/video-intercom'
            style={currentPage === '/video-intercom' ? { transform: 'scale(1.1)', color: 'rgb(223, 223, 0)' } : {}}
          >
            <img className="linking-image" src={videointercom} alt="VideoIntercom" />
            <p>Відеодомофони</p>
          </Link>
          <Link
            className="catalogButt"
            to='/call-panels'
            style={currentPage === '/call-panels' ? { transform: 'scale(1.1)', color: 'rgb(223, 223, 0)' } : {}}
          >
            <img className="linking-image" src={callpanel} alt="CallPanel" />
            <p>Викличні панелі</p>
          </Link>
          <Link
            className="catalogButt"
            to='/equipment-kits'
            style={currentPage === '/equipment-kits' ? { transform: 'scale(1.1)', color: 'rgb(223, 223, 0)' } : {}}
          >
            <img className="linking-image" src={equipmentkit} alt="EquipmentKit" />
            <p style={{ width: '160px' }}>Комплекти обладнання</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export { Linking }