import '../css/buy.css';
import { useState } from 'react';

import BACKEND_URL from '../../config';

const ChangeName = (props) => {

    const [changingName, setChangingName] = useState()

    const pushName = async (e) => {
        e.preventDefault()

        const formData = {
            'id': props.productID,
            'dbClass': props.productDBclass,
            'name': changingName
        }

        try {
            await fetch(`${BACKEND_URL}/change_name`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            props.closeWindow();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }

    }

    return (
        <div>
            <div className="modal">
                <div className="modal-content" style={{overflow: 'hidden', height: 'auto'}}>
                    <h2 style={{ textAlign: 'center' }}>Поточна назва: {props.productName}</h2>
                    <form>
                        <div className="group" style={{ marginTop: '30px' }}>
                            <input
                                type="text"
                                required
                                value={changingName}
                                style={{ background: 'none', color: 'white', width: 'calc(100% - 20px)' }}
                                onChange={(e) => setChangingName(e.target.value)}
                            />
                            <span className="bar"></span>
                            <label>*Нова назва товару</label>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <button className='floating-button' onClick={props.closeWindow}>Закрити</button>
                            <button type='submit' className='floating-button' onClick={pushName}>Змінити</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export { ChangeName };
