import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import '../css/buy.css';

import { DoUWannaDelete } from './DoUWannaDelete';

const ViewImagesForAdmin = ({ images, closeWindow }) => {
  const [ActiveWindow, setActiveWindow] = useState(false)
  const [currentID, setCurrentID] = useState()

  function openWin(e) {
    setCurrentID(e.target.id)
    setActiveWindow(true)
  }

  function closeWin() {
    setActiveWindow(false);
    closeWindow();
  }

  function ifNo() {
    setActiveWindow(false);
  }

  return (
    <>
      <div className="modal">
        <div className="modal-content" style={{ height: 'auto', maxHeight: '600px', width: '800px', overflowX: 'hidden' }}>
          <div className='main-modal-link-button-div' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Link className='main-modal-link-button' style={{ width: '30%' }} onClick={closeWindow}>Закрити</Link>
          </div>
          <div className="image-gallery-2" style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px', marginBottom: '20px' }}>
            {images.map((image, index) => (
              <div>
                <div className='view-images-for-admin-img-div'>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '5px'}}>
                    <button className='view-images-for-admin-delete-button' id={image.id} onClick={openWin}>Видалити</button>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img
                      key={index}
                      src={image.url}
                      alt={image.filename}
                      style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }}

                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {ActiveWindow &&
        <DoUWannaDelete currentID={currentID} closeWin={closeWin} ifNo={ifNo} />
      }
    </>
  );
};

export { ViewImagesForAdmin };
