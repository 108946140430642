import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import BACKEND_URL from '../config';

import './css/buy.css'
import './css/spinner.css'

const Dahua = () => {
  const [Dahua, setDahua] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
    const response = await axios.get(`${BACKEND_URL}/get_product`, {
        params: { option: 'Dahua' }
        });
      setDahua(response.data.get_list);
      setLoading(false)
    } catch (error) {
      console.error('Error during request: ', error);
      alert('Error during request');
    }
  };

  return (
    <>
    <div style={{marginTop: '-50px'}}>
      <title>Dahua</title>
      {loading ? <div>
              <div className="spinner"></div>
              <div className='text-snr'><p>ЗАВАНТАЖУЄМО КОНТЕНТ...</p></div>
            </div> :
            <div>
                 <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                {Dahua.map((image) => (
                  <div className='div-for-catalog-two' key={image.id} style={{opacity: image.avaliable ? '1' : '0.8'}}>
                    <p style={{ position: 'absolute', marginTop: '10px', fontSize: '25px' }}>{image.product}</p>
                    <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '20px' }}>{image.price}грн</p>
                    <img
                      src={image.url}
                      alt={image.filename}
                      style={{ blockSize: '250px', marginTop: '100px', position: 'absolute' }}
                    />
                  <div style={{ position: 'absolute', marginTop: '440px'}}>
                  {image.description.length > 240 ? 
                  <div>
                    <div className='description'>
                      <p>{image.description.slice(0, 237) + '...'}</p>
                    </div>
                    <div style={{marginLeft: '100px', width: '300px'}}>
                      <Link className='link-for-text' to={`/pre-order-page/Dahua/${image.id}`}>Переглянути увесь опис товару↴</Link>
                    </div>
                    </div>
                    : 
                    <div className='description'>
                      <p>{image.description}</p>
                    </div>}
                  </div>
                    <Link to={`/pre-order-page/Dahua/${image.id}`} className='catalog-link' 
                    style={{ position: 'absolute', marginTop: '630px'}}>
                      {image.avaliable ? 'Замовити' : 'Немає у наявності'}
                    </Link>
                  </div>
                ))}
              </div>
              </div>
            </div>}
        </div>
      </>
  );
};

export { Dahua };
