import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import BACKEND_URL from '../config';

import for_about_main from '../images/for_about_main.png'

import { useEditor, EditorContent } from '@tiptap/react';
import Link from '@tiptap/extension-link';
import Italic from '@tiptap/extension-italic';
import Bold from '@tiptap/extension-bold';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import Paragraph from '@tiptap/extension-paragraph';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import HardBreak from '@tiptap/extension-hard-break';

const ManageAboutUs = () => {
    const [content, setContent] = useState(" ");
    const [draft, setDraft] = useState("");
    const [loader, setLoader] = useState(false);
    const [saving, setSaving] = useState(false);
    const [page, setPage] = useState(1);
    const [inputPage, setInputPage] = useState(1);
    const [draftStatus, setDraftStatus] = useState('Чернетка збережена.');
    const [timeLeft, setTimeLeft] = useState(null)

    const customParagraph = Paragraph.extend({
        addAttributes() {
            return {
                ...this.parent?.(),
                style: {
                    default: 'white-space: pre-wrap; margin-bottom: -15px;',
                },
            };
        },
        parseHTML() {
            return [
                {
                    tag: 'p',
                    getAttrs: node => {
                        const element = /** @type {HTMLElement} */ (node);
                        if (element.innerHTML === '&nbsp;' || element.innerHTML === ' ') {
                            return { 'data-empty': true };
                        }
                        return null;
                    },
                },
            ];
        },
        renderHTML({ HTMLAttributes }) {
            if (HTMLAttributes['data-empty']) {
                return ['p', { 'data-empty': true }, ' '];
            }
            return ['p', HTMLAttributes, 0];
        },
    });

    const CustomLink = Link.extend({
        addAttributes() {
            return {
                ...this.parent?.(),
                class: {
                    default: 'custom-link', // Додаємо кастомний клас
                },
                style: {
                    default: 'color: #3399ee; text-decoration: none; cursor: pointer;', // Стилізація посилання
                },
            };
        },
    });

    const editor = useEditor({
        extensions: [
            customParagraph,
            Document,
            Underline,
            Bold,
            TextStyle,
            Color,
            Italic,
            CustomLink,
            Text,
            ListItem,
            BulletList,
            HardBreak,
        ],
        content: '',
    });


    useEffect(() => {
        axios.post(`${BACKEND_URL}/get-current-article`, { id: 1 })
            .then((res) => {
                setContent(res.data.text);
                setDraft(res.data.draft);
                setLoader(true);
                if (editor) {
                    editor.commands.setContent(res.data.text);
                }
            });
    }, [editor]);

    useEffect(() => {
        if (!editor) return;

        const updateContent = () => {
            setContent(editor.getHTML());
            setDraft(editor.getHTML());
            setDraftStatus('Збереження...');
            setTimeLeft(5);
        };

        editor.on('update', updateContent);
        return () => editor.off('update', updateContent);
    }, [editor]);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevCount) => prevCount - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else {
            if (timeLeft !== null) {
                axios
                    .post(`${BACKEND_URL}/save-article-draft`, {
                        id: 1,
                        draft: content
                    })
                    .then((res) => setDraftStatus(res.data));
            }
        }
    }, [timeLeft]);

    function saveChanges() {
        setSaving(true);
        axios.post(`${BACKEND_URL}/save-article-changes`, {
            id: 1,
            text: content
        }).then(() => {
            setSaving(false);
        });
    }

    const setLink = useCallback(() => {
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run()

            return
        }

        // update link
        try {
            editor.chain().focus().extendMarkRange('link').setLink({ href: url })
                .run()
        } catch (e) {
            alert(e.message)
        }
    }, [editor])

    if (!editor) {
        return null
    }

    return (
        <div>
            <title>Редагування сторінки "Про Нас"</title>
            {loader && (
                <div className='main-manage-div' style={{ position: 'relative', marginTop: '155px' }}>
                    <div>
                        <button className='manage-pages-button' style={page === 1 ? { backgroundColor: 'rgb(45, 45, 45)', opacity: '1' } : {}}
                            onClick={() => setPage(1)}>Редагування</button>
                        <button className='manage-pages-button' style={page === 2 ? { backgroundColor: 'rgb(45, 45, 45)', opacity: '1' } : {}}
                            onClick={() => setPage(2)}>Перегляд</button>
                    </div>
                    <hr style={{ opacity: '0.5', borderTop: '1px solid #333' }}></hr>
                    {page === 1 &&
                        <div>
                            <div className="menu-bar" style={{ marginTop: '-18px' }}>
                                <button className='manage-button' onClick={() => { editor.chain().focus().toggleBold().run(); }}
                                    style={{ fontWeight: 'bold' }}>
                                    Жирний
                                </button>
                                <button className='manage-button' onClick={() => { editor.chain().focus().toggleItalic().run(); }}
                                    style={{ fontStyle: 'italic' }}>
                                    Курсив
                                </button>
                                <button
                                    style={{ textDecoration: 'underline' }}
                                    className={`manage-button ${editor.isActive('underline') ? 'is-active' : ''}`}
                                    onClick={() => { editor.chain().focus().toggleUnderline().run(); }}
                                >
                                    Підкреслений
                                </button>
                                <button
                                    onClick={() => { editor.chain().focus().toggleBulletList().run() }}
                                    className={`manage-button ${editor.isActive('bulletList') ? 'is-active' : ''}`}
                                >
                                    ● Список
                                </button>
                                <button
                                    style={{ color: '#fe6512' }}
                                    onClick={() => {
                                        const currentColor = editor.getAttributes('textStyle').color;
                                        const newColor = currentColor === '#fe6512' ? '#ffffff' : '#fe6512';
                                        editor.chain().focus().setColor(newColor).run();
                                    }}
                                    className={`manage-button ${editor.isActive('textStyle', { color: '#fe6512' }) ? 'is-active' : ''}`}
                                    data-testid="setOrange"
                                >
                                    Оранжевий
                                </button>
                                <button onClick={setLink} className={`manage-button ${editor.isActive('link') ? 'is-active' : ''}`}
                                    style={{ color: '#3399ee' }}>
                                    Посилання
                                </button>
                            </div>

                            <EditorContent editor={editor} className='manage-input' />
                            <p style={{ position: 'absolute', right: '5px', opacity: '0.5', fontSize: '15px', top: window.innerWidth > 450 ? '0px' : '-40px' }}>{draftStatus}</p>
                            <div style={{ position: 'absolute', left: '0px', marginTop: '-10px' }}>
                                <button
                                    className='manage-change-button'
                                    style={inputPage === 1 ? { backgroundColor: 'rgb(56, 56, 56)', borderRadius: '0px 0px 0px 5px' } : { borderRadius: '0px 0px 0px 5px' }}
                                    disabled={saving}
                                    onClick={() => { setInputPage(1); editor.commands.setContent(content) }}
                                >
                                    Поточний
                                </button>
                                <button
                                    className='manage-change-button'
                                    style={inputPage === 2 ? { backgroundColor: 'rgb(56, 56, 56)', borderRadius: '0px 0px 5px 0px', marginLeft: '0px' } : { borderRadius: '0px 0px 5px 0px', marginLeft: '0px' }}
                                    disabled={saving}
                                    onClick={() => { setInputPage(2); editor.commands.setContent(draft) }}
                                >
                                    Чернетка
                                </button>
                            </div>
                            <div style={{ height: '55px' }}></div>
                            <div style={{ position: 'absolute', right: '5px', marginTop: window.innerWidth > 400 ? '-55px' : '0px' }}>
                                <button className='manage-button' style={{ borderRadius: '5px' }} disabled={saving} onClick={saveChanges}>
                                    {saving ? 'Публікація...' : 'Опублікувати'}
                                </button>
                            </div>
                        </div>
                    }
                    {page === 2 && content && (
                        <div className='preview-main-div'>
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <img src={for_about_main} style={{ blockSize: '50px' }} alt='about-img' />
                                    <p style={{ color: 'rgb(226, 101, 33)', marginTop: '33px', marginLeft: '-37px', fontSize: '12px' }}>®</p>
                                </div>
                                <div
                                    style={{ whiteSpace: 'pre-wrap', marginBottom: '100px' }}
                                    dangerouslySetInnerHTML={{ __html: `<div class="custom-content">${content}</div>` }}
                                />
                                <style>{`.custom-content p { margin-bottom: -15px; }`}</style>
                                <footer style={{ textAlign: 'center', opacity: '0.5' }}>
                                    <p style={{ marginTop: '100px', fontSize: '20px' }}>Тов Домосервіс</p>
                                    <p>Адреса:</p>
                                    <p style={{ marginTop: '-15px' }}>м. Львів</p>
                                    <p style={{ marginTop: '-15px' }}>вул. Зелена 115А</p>
                                    <p style={{ marginTop: '-10px', marginBottom: '100px' }}>© 2008-2025</p>
                                </footer>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ManageAboutUs;