import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

import BACKEND_URL from '../config';

import { Buy } from './components/Buy';
import Cart from './components/Cart';

import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";

const PreOrderPage = (props) => {
    const { option, id } = useParams()
    const navigate = useNavigate()
    const [product, setProduct] = useState()
    const [mainImage, setMainImage] = useState({ 'url': '', 'filename': '' })
    const [inCart, setInCart] = useState(false)

    const [checkOrder, setCheckOrder] = useState(false)

    const [imgIndexTaple, setImgIndexTaple] = useState({
        'first': 0, 'second': window.innerWidth > 660 ? window.innerWidth >= 1030 ? 4 : window.innerWidth >= 830 ? 3 : 2 : 3
    })

    const [openCart, setOpenCart] = useState(false)

    useEffect(() => {
        axios
            .post(`${BACKEND_URL}/get_current_product`, { option: option, id: id })
            .then((res) => {
                if (res.data.error) {
                    navigate('/404')
                } else {
                    setProduct(res.data)
                    setMainImage({ 'url': res.data.product_json.url, 'filename': res.data.product_json.filename })
                    const data = localStorage.getItem("CartProducts");
                    if (data) {
                        const parsedData = JSON.parse(data).find((el) => el.id == res.data.product_json.id && el.dbClass == res.data.product_json.dbClass);
                        if (parsedData) {
                            setInCart(true)
                        }
                    }
                }
            })
    }, [])

    function changeImageIndex(action) {
        if (action === 'right') {
            if (imgIndexTaple.second <= product.list_images_json.length - 1) {
                setImgIndexTaple({ 'first': imgIndexTaple.first + 1, 'second': imgIndexTaple.second + 1 })
            }
        } else {
            if (imgIndexTaple.first !== 0) {
                setImgIndexTaple({ 'first': imgIndexTaple.first - 1, 'second': imgIndexTaple.second - 1 })
            }
        }
    }

    function addProductToCart() {
        if (!product.product_json.avaliable) {
            return
        }

        if (inCart) {
            setOpenCart(true)
            return
        }
        
        const data = localStorage.getItem("CartProducts");
        const cart = data ? JSON.parse(data) : [];

        cart.push(product.product_json);

        localStorage.setItem("CartProducts", JSON.stringify(cart));

        props.setProductNumber(props.productNumber + 1)

        setOpenCart(true);
        setInCart(true)
    }

    useEffect(() => {
        if (product) {
            const data = localStorage.getItem("CartProducts")
            const cart = data ? JSON.parse(data) : [];

            if (!cart.find((el) => el.id === product.product_json.id)) {
                setInCart(false)
            }

            console.log('useEffect worked')
        }

    }, [])


    return (
        <div>
            {!product ?
                <div>
                    <div className="spinner"></div>
                    <div className='text-snr'><p>ЗАВАНТАЖУЄМО КОНТЕНТ...</p></div>
                </div>
                :
                <div className='pre-order-main-flex-div'>
                    <title>Купити {product.product_json.product}</title>
                    <div className='pre-order-between-div'>
                        <div className='pre-order-image-div-width'>
                            <div className='pre-order-div' style={{ height: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                        src={mainImage.url}
                                        alt={mainImage.filename}
                                        className='pre-order-main-image'
                                    />
                                </div>
                                {product.list_images_json.length > 1 &&
                                    <div className='pre-order-image-list-div'>
                                        {product.list_images_json.length > 4 &&
                                            <FaChevronLeft className='pre-order-chevron-icon' onClick={() => changeImageIndex('left')} />
                                        }
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {product.list_images_json
                                                .sort((a, b) => a.id - b.id)
                                                .slice(imgIndexTaple.first, imgIndexTaple.second)
                                                .map((el) => (
                                                    <div className='pre-order-list-img-div' key={el.id} onClick={() => setMainImage({ url: el.url, filename: el.filename })}>
                                                        <img src={el.url} alt={el.filename} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                                    </div>
                                                ))}
                                        </div>
                                        {product.list_images_json.length > 4 &&
                                            <FaChevronRight className='pre-order-chevron-icon' onClick={() => changeImageIndex('right')} />
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='pre-order-div' style={{ display: 'flex', width: window.innerWidth <= 660 ? 'calc(100% - 10px)' : '60%' }}>
                            <div className='pre-order-info-div-width' style={{ display: 'flex' }}>
                                <div style={{ margin: '10px', width: '100%' }}>
                                    <div style={{ opacity: '1' }} >
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h1 style={{ marginTop: '0px', marginRight: '10px', wordBreak: 'break-all' }}>Купити {product.product_json.product}</h1>
                                            <button
                                                className='pre-order-buy-button'
                                                onClick={addProductToCart}
                                                style={{ opacity: product.product_json.avaliable ? '1' : '0.8' }}
                                            >
                                                {product.product_json.avaliable ? inCart ? "У кошику" : "Додати в кошик" : "Немає у наявності"}
                                            </button>
                                        </div>
                                        <h3 style={{ marginTop: '-10px' }}>Ціна: <span style={{ textDecoration: 'underline' }}>{product.product_json.price}₴</span></h3>
                                        {product.product_json.description !== '-' && <div>
                                            <h3>Опис:</h3>
                                            <p style={{ marginTop: '-15px' }}>{product.product_json.description}</p>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {checkOrder && <Buy productPrice={product.product_json.price} closeWindow={() => setCheckOrder(false)} />}
                    {openCart && <Cart setOpenCart={setOpenCart} setCheckOrder={setCheckOrder} setProductNumber={props.setProductNumber}
                        productNumber={props.productNumber} setInCart={setInCart} id={product.product_json.id} />}
                </div>
            }
        </div>
    );
}

export default PreOrderPage;
