import one from './images/one.png';
import two from './images/two.png';
import three from './images/three.png'
import nine from './images/9.png'

import React from 'react';
import { useNavigate } from 'react-router-dom';

const Catalog = () => {
    const navigate = useNavigate()

    return (
        <div>
            <title>Каталог</title>
            <div className="catalog-div">
                <h2 style={{marginLeft: '10px', marginRight: '10px'}}>Каталог обладнання під багатоквартирну систему "Vizit", "Cyfral" та "Мрія"</h2>
            </div>
            <div className='catalog-main-center-div'>
                <div className='catalog-main-div'>
                    <div className="catalog-button-main-div" onClick={() => navigate('/audiotubes')}>
                        <div className="catalog-button-div">
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img className="catalog-button-img" src={one} alt="one" />
                                </div>
                                <p style={{ textAlign: 'center' }}>Аудіотрубки</p>
                            </div>
                        </div>
                        <div className='catalog-retractable-main-div'>
                            <hr style={{ opacity: '0.5', borderTop: '1px solid #333' }}></hr>
                            <p style={{ margin: '5px' }}>
                                <span style={{ fontWeight: 'bold' }}>Відео та звуковий зв'язок: </span>Передає інформацію як звук, так і відео.
                                Дозволяє переглядати відвідувачів на екрані або моніторі.  <br />
                                <span style={{ fontWeight: 'bold' }}>Простота: </span>Зазвичай менш складний і більш доступний з погляду вартості, оскільки не включає в себе складних відеокамер та екранів. <br />
                                <span style={{ fontWeight: 'bold' }}>Базовий рівень безпеки: </span>Забезпечує основний рівень безпеки та зручності, але не надає візуальну інформацію.
                            </p>
                        </div>
                    </div>
                    <div className="catalog-button-main-div" onClick={() => navigate('/video-intercom')}>
                        <div className="catalog-button-div">
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img className="catalog-button-img" src={two} alt="two" />
                                </div>
                                <p style={{ textAlign: 'center' }}>Відеодомофони</p>
                            </div>
                        </div>
                        <div className='catalog-retractable-main-div'>
                            <hr style={{ opacity: '0.5', borderTop: '1px solid #333' }}></hr>
                            <p style={{ margin: '5px' }}>
                                <span style={{ fontWeight: 'bold' }}>Звуковий зв'язок: </span>Основна функція аудіодомофона -
                                передача голосового зв'язку між абонентами.
                                Він дозволяє спілкуватися за допомогою звуку, але не передає відеоінформацію. <br />
                                <span style={{ fontWeight: 'bold' }}>Візуальна ідентифікація: </span>Надає можливість візуально ідентифікувати осіб,
                                які перебувають перед вхідними дверима чи брамою. <br />
                                <span style={{ fontWeight: 'bold' }}>Додаткові функції: </span>Може включати режими відеоспостереження, можливість зберігати
                                відеозаписи, функції контролю доступу, можливість підєднання до смартфону для та
                                переадресації виклику віддаленого перегляду зображення перед дверима як онлайн та і в записі. <br />
                                <span style={{ fontWeight: 'bold' }}>Вищий рівень безпеки: </span>Забезпечує вищий рівень безпеки за рахунок візуального спостереження.
                            </p>
                        </div>
                    </div>
                    <div className="catalog-button-main-div" onClick={() => navigate('/call-panels')}>
                        <div className="catalog-button-div">
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img className="catalog-button-img" src={nine} alt="nine" />
                                </div>
                                <p style={{ textAlign: 'center' }}>Викличні панелі</p>
                            </div>
                        </div>
                        <div className='catalog-retractable-main-div'>
                            <hr style={{ opacity: '0.5', borderTop: '1px solid #333' }}></hr>
                            <p style={{ margin: '5px' }}>
                                Це пристрій, розташований біля вхідних дверей квартири, який підключається до додаткового каналу відеодомофону.
                                Цей пристрій має вбудовану камеру, мікрофон і динамік, а також кнопку виклику.З нього можна вести запис по руху
                                всього що відбувається в коридорі (за умови підтримки функції DVR відеодомофоном)
                            </p>
                        </div>
                    </div>
                    <div className="catalog-button-main-div" onClick={() => navigate('/equipment-kits')}>
                        <div className="catalog-button-div" style={{marginBottom: '0px', marginTop: '0px'}}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0px' }}>
                                    <img className="catalog-button-img" src={three} alt="three" />
                                </div>
                                <p style={{ textAlign: 'center' }}>Комплекти обладнання</p>
                            </div>
                        </div>
                        <div className='catalog-retractable-main-div'>
                            <hr style={{ opacity: '0.5', borderTop: '1px solid #333' }}></hr>
                            <p style={{ margin: '5px'}}>
                                Це синхронізована між собою система з декількох модулів. Сучасні домофонні комплекси 
                                можуть працювати через мобільні додатки, підтримувати хмарне зберігання даних та інтегруватися з розумними будинками. 
                                Якщо точніше - то <br />
                                <span style={{fontWeight: 'bold'}}>вони містять в собі такі функції: </span>ідентифікація відвідувачів, віддалене відкриття дверей, робота з електронними ключами,
                                відеоспостереження, внутрішній зв'язок між квартирами, хмарне збереження даних, логування ведення подій 
                                (запис часу входу/виходу та історії дзвінків), нічний режим та багато іншого.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export { Catalog };
