import React from 'react';
import '../css/buy.css';

import BACKEND_URL from '../../config';

const DoUWannaDelete = ({ currentID, closeWin, ifNo }) => {

    const deleteSelectImg = async (e) => {
        e.preventDefault();

        const formData = {
            'id': currentID
        }

        try {
            await fetch(`${BACKEND_URL}/delete_select_img`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            closeWin();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }
    }


    return (
        <div>
            <div className="modal">
                <div className="modal-content" style={{ overflow: 'hidden', height: 'auto' }}>
                    <h2 style={{ textAlign: 'center' }}>Ви дійсно хочете видалити це зображення?</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className='floating-button' onClick={ifNo}>Ні</button>
                        <button type='submit' className='floating-button' onClick={deleteSelectImg}>Так</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { DoUWannaDelete };
