import '../css/buy.css';

import BACKEND_URL from '../../config';

const Delete = (props) => {
    const deleteEl = async (e) => {
        e.preventDefault();

        const formData = {
            id: props.productID,
            dbClass: props.productDBclass
        };

        try {
            await fetch(`${BACKEND_URL}/delete_product`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            props.closeWindow();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }
    };

    return (
        <div>
            <div className="modal">
                <div className="modal-content" style={{ overflow: 'hidden', height: 'auto' }}>
                    <h2 style={{ textAlign: 'center' }}>Ви дійсно хочете видалити цей товар?</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className='floating-button' onClick={props.closeWindow}>Ні</button>
                        <button type='submit' className='floating-button' onClick={deleteEl}>Так</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Delete };
